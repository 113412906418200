import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { LoadingService } from '../../shared/components/loading/loading.service';
import { finalize } from "rxjs";
import { SkipLoading } from '../../shared/components/loading/skip-loading.component';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.context.get(SkipLoading)) {
    return next(req);
  }
  const loadingService = inject(LoadingService);
  loadingService.loadingOn();
  return next(req)
    .pipe(
      finalize(() => {
        loadingService.loadingOff()
      })
    )
};