import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { SubscriptionTickets } from "../../../core/model/subscription-tickets";
import { TicketService } from "../../../core/services/ticket.service";

export const subscriptionTicketsResolver: ResolveFn<SubscriptionTickets | null> =
  async (route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {
    const subscriptionId = route.paramMap.get("id");
    if (!subscriptionId) {
      return null;
    }
    const ticketService = inject(TicketService);
    return ticketService.getForSubscription(Number(subscriptionId));
  }
