import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { SubscriptionService } from "../../../core/services/subscription.service";
import { Subscription } from "../../../core/model/subscription";

export const subscriptionResolver: ResolveFn<Subscription | null> =
  async (route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {
    const subscriptionId = route.paramMap.get("id");
    if (!subscriptionId) {
      return null;
    }
    const subscriptionService = inject(SubscriptionService);
    return subscriptionService.get(Number(subscriptionId));
  }
