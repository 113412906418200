<h2 mat-dialog-title class="severity-{{ data.severity }}">
    <mat-icon>{{getIcon(data.severity)}}</mat-icon>
    <button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="severity-{{ data.severity }}">
    <p>{{ data.text}}</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onClose()">{{ data.cancelText }}</button>
    @if (showConfirmButton()){
    <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ data.confirmText }}</button>
    }
</mat-dialog-actions>