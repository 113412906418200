import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubscriptionTickets } from '../model/subscription-tickets';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private http: HttpClient = inject(HttpClient);
  private envService = inject(EnvironmentService);
  private apiUrl: string = this.envService.getValue('apiUrl')

  async getForSubscription(subscriptionId: number): Promise<SubscriptionTickets> {
    const tickets$ =this.http.get<SubscriptionTickets>(
      `${this.apiUrl}/tickets/subscription/${subscriptionId}`,
      { observe: 'body', responseType: 'json' }
    );

    return await firstValueFrom(tickets$);
  }
}