import { inject, Injectable } from "@angular/core";
import { MessagePopupSeverity } from "./message-popup-data.model";
import { MessagePopup } from "./message-popup";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MessagePopupService {

  private dialog: MatDialog = inject(MatDialog);

  showMessage(text: string, severity: MessagePopupSeverity, confirmText: string = '', cancelText: string = 'Închide'): Promise<boolean> {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.data = { text, severity, confirmText, cancelText };

    const close$ = this.dialog.open(MessagePopup, config)
      .afterClosed();

    return firstValueFrom(close$);
  }
}