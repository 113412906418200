import { Injectable, inject } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subscription } from '../model/subscription';
import { MpFormDataSubscription } from '../model/mp-form-data-subscription';
import { MpFormData } from '../model/mp-form-data';
import { EnvironmentService } from './environment.service';
import { LoadingService } from '../../shared/components/loading/loading.service';
import { AuthUser } from '../model/auth-user';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private http: HttpClient = inject(HttpClient);
  private loadingService: LoadingService = inject(LoadingService);
  private utils: UtilsService = inject(UtilsService);
  private envService = inject(EnvironmentService);
  private apiUrl: string = this.envService.getValue('apiUrl')

  async get(id: number): Promise<Subscription> {
    const subscription$ = this.http.get<Subscription>(
      `${this.apiUrl}/subscriptions/${id}`
    );
    return await firstValueFrom(subscription$);
  }

  async create(subscription: Partial<Subscription>): Promise<MpFormDataSubscription> {
    const data$ = this.http.post<MpFormDataSubscription>(
      `${this.apiUrl}/subscriptions/`,
      subscription
    )
    return await firstValueFrom(data$);
  }

  async getByClient(client: AuthUser): Promise<Subscription[]> {
    const today = this.utils.toDbDate(new Date());
    let url = `${this.apiUrl}/subscriptions/?client_id=${client.id}`;
    if (client.no_history == 1) {
      url += `&valid_to=>=${today}`;
    }
    url += '&sort(-id)';
    const subscriptions$ = this.http.get<Subscription[]>(url);
    return await firstValueFrom(subscriptions$);
  }

  async getPaymentForm(subscriptionId: number): Promise<MpFormData> {
    const mpFormData$ = this.http.get<MpFormData>(
      `${this.apiUrl}/subscriptions/pf/${subscriptionId}`
    );

    return await firstValueFrom(mpFormData$);
  }

  createAndSubmitPaymentForm(mpFormData: MpFormData) {
    this.loadingService.loadingOn();

    let paymentForm = document.createElement('form');
    paymentForm.action = this.envService.getValue('paymentUrl');
    paymentForm.method = 'POST';

    let envKeyElm = document.createElement('input');
    envKeyElm.type = 'hidden';
    envKeyElm.name = 'env_key';
    envKeyElm.value = mpFormData.env_key;
    paymentForm.appendChild(envKeyElm);

    let dataElm = document.createElement('input');
    dataElm.type = 'hidden';
    dataElm.name = 'data';
    dataElm.value = mpFormData.data;
    paymentForm.appendChild(dataElm);

    let cipherElm = document.createElement('input');
    cipherElm.type = 'hidden';
    cipherElm.name = 'cipher';
    cipherElm.value = mpFormData.cipher;
    paymentForm.appendChild(cipherElm);

    let ivElm = document.createElement('input');
    ivElm.type = 'hidden';
    ivElm.name = 'iv';
    ivElm.value = mpFormData.iv;
    paymentForm.appendChild(ivElm);

    document.body.appendChild(paymentForm);
    paymentForm.submit();
  }

  getUsableTrainsByDate(subscriptionId: number, date: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/subscriptions/trains/${subscriptionId}/${date}`
    );
  }
}
