import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { EnvironmentService } from '../services/environment.service';

export const contentHashInterceptor: HttpInterceptorFn = (req, next) => {

  const auth = inject(AuthService);
  const utils = inject(UtilsService);
  const envService = inject(EnvironmentService);

  let headers: any = {
    'Content-Hash': envService.getValue('contentHash'),
    'X-Date': utils.dateToYmd(new Date()),
  }

  if (auth.isLoggedIn()) {
    headers['X-SID'] = auth.user()?.session?.sid || '';
  }

  // Clone the request and add the authorization header
  const authReq = req.clone({
    setHeaders: headers
  });

  // Pass the cloned request with the updated header to the next handler
  return next(authReq);
};
