import { ApplicationConfig, InjectionToken, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { contentHashInterceptor } from './core/interceptors/contentHash.interceptor';
import { errorCatchingInterceptor } from './core/interceptors/error-catching.interceptor';
import { loadingInterceptor } from './core/interceptors/loading.interceptor';
import { DatePipe } from '@angular/common';
import { environment } from '../environments/environment';

export const ENVIRONMENT = new InjectionToken<{ [key: string]: any }>('environment');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([contentHashInterceptor, errorCatchingInterceptor, loadingInterceptor])),
    DatePipe,
    { provide: ENVIRONMENT, useValue: environment }
  ]
};
