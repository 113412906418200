import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from '@angular/material/dialog';
import { MessagePopupData } from './message-popup-data.model';
import { MatIcon } from '@angular/material/icon';

@Component({
	selector: 'message-popup',
	templateUrl: './message-popup.html',
	styleUrl: './message-popup.scss',
	standalone: true,
	imports: [
		MatButtonModule,
		MatDialogTitle,
		MatDialogContent,
		MatDialogActions,
		MatDialogClose,
		MatIcon,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MessagePopup {
	readonly dialogRef = inject(MatDialogRef);
	readonly data = inject<MessagePopupData>(MAT_DIALOG_DATA);

	onClose() {
		this.dialogRef.close();
	}

	getIcon(severity: string) {
		switch (severity) {
			case 'error':
				return 'error';
			case 'warning':
				return 'warning';
			case 'info':
				return 'info';
			case 'success':
				return 'check';
			default:
				return 'info';
		}
	}

	showConfirmButton() {
		return this.data.confirmText != '';
	}
}