import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";


export const authGuard: CanActivateFn =
	(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const authService = inject(AuthService);
		const router = inject(Router);
		if (authService.isLoggedIn()) {
			return true;
		}
		else {
			authService.setLastVisitedUrl(state.url);
			return router.parseUrl('/login')
		}
	}